import * as API from "@/api/index";

export default {
  reportEntity_storeList: (params) => {
    return API.POST("api/reportEntity/storeList", params);
  },
  // 
  customerLoseWeight_list: (params) => {
    return API.POST("api/customerLoseWeight/list", params);
  },
  // 
  customerLoseWeight_excel: (params) => {
    return API.exportExcel("api/customerLoseWeight/excel", params);
  },
};
